import React from 'react'
import PropTypes from 'prop-types'
import IconWithLink from '../../shared/icon-with-link/icon-with-link';
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';

import "./social-media-section.scss"

import facebookIconSrc from '../../../images/icons/facebook-circle-icon.svg'
import twitterIconSrc from '../../../images/icons/twitter-circle-icon.svg'

function SocialMediaSection(props) {
    function scrollIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: '.social-media-section',
                opacity: [0, 1],
                delay: 500,
                duration: 250,
                direction: 'reverse',
                easing: 'linear'
            })
            .add({
                targets: '.social-media-section-actions',
                opacity: [0, 1],
                direction: 'reverse',
                duration: 250,
                easing: 'linear'
            });
    }

    function scrollOut() {
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <section className="social-media-section">
                <p className="social-media-section-desc">{props.description}</p>
                <div className="social-media-section-actions">
                    <IconWithLink linkLabel={props.facebookLinkLabel} urlLink={props.facebookLink} iconSrc={facebookIconSrc} />
                    <IconWithLink linkLabel={props.twitterLinkLabel} urlLink={props.twitterLink} iconSrc={twitterIconSrc} />
                </div>
            </section>
        </Waypoint>
    )
}

SocialMediaSection.propTypes = {
    description: PropTypes.string,
    facebookLinkLabel: PropTypes.string,
    twitterLinkLabel: PropTypes.string,
    facebookLink: PropTypes.string,
    twitterLink: PropTypes.string
}

SocialMediaSection.defaultProps = {
    description: ``,
    facebookLinkLabel: ``,
    twitterLinkLabel: ``,
    facebookLink: ``,
    twitterLink: ``
}

export default SocialMediaSection

