import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import { ReCaptcha } from "../../core/recaptcha/recaptcha";
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';

import "./contact-form.scss"
import Button from '../../shared/button/button'

const CAPTCHA_SITE_KEY = "6Lcra8EUAAAAAEzc-4hvZOKk6Ry0zdkUOTEdidWl";

export class ContactForm extends Component {
    static propTypes = {
        description: PropTypes.string,
        nameLabel: PropTypes.string,
        emailLabel: PropTypes.string,
        phoneLabel: PropTypes.string,
        companyLabel: PropTypes.string,
        messageLabel: PropTypes.string,
        submitLabel: PropTypes.string,
        submitingLabel: PropTypes.string,
        formResult: PropTypes.func
    }

    static defaultProps = {
        description: ``,
        nameLabel: ``,
        emailLabel: ``,
        phoneLabel: ``,
        companyLabel: ``,
        messageLabel: ``,
        submitLabel: ``,
        submitingLabel: ``,
        formResult: () => { }
    }

    constructor(props) {
        super(props);

        this.state = {
            name: {
                id: "name",
                value: '',
                validations: {
                    required: true,
                    message: "Por favor complete este campo requerido."
                },
                valid: false,
                touched: false
            },
            email: {
                id: "email",
                value: '',
                validations: {
                    required: true,
                    regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
                    message: "Por favor ingrese una direcion de correo electrónico válida."
                },
                valid: false,
                touched: false
            },
            phone: {
                id: "phone",
                value: '',
                validations: {
                    // required: false,
                    // regex: false
                },
                valid: false,
                touched: false
            },
            company: {
                id: "company",
                value: '',
                validations: {
                    // required: false,
                    // regex: false
                },
                valid: false,
                touched: false
            },
            message: {
                id: "message",
                value: '',
                validations: {
                    required: true,
                    message: "Por favor complete este campo requerido."
                    // regex: false
                },
                valid: false,
                touched: false
            },
            formIsValid: false,
            recaptchaToken: '',
            sending: false
        }

        this.inputChangedHandler = this.inputChangedHandler.bind(this);
        this.verifyDataToSend = this.verifyDataToSend.bind(this);
        this.submitData = this.submitData.bind(this);

    }

    componentDidMount() {

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`;
        document.querySelector('.contact-form').appendChild(script);

    }

    componentWillUnmount() {
        let recaptchaBadge = document.querySelector('.grecaptcha-badge');
        recaptchaBadge.parentNode.removeChild(recaptchaBadge);
    }

    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        this.setState({ recaptchaToken: recaptchaToken });
        // console.log('My Last token ==>', this.state.recaptchaToken);
    }

    inputValidation(value, validations) {
        let isValid = true;
        if (validations.required) {
            isValid = (value !== '') && isValid;
        }

        if (validations.regex) {
            // console.log('regex on');
            isValid = validations.regex.test(value) && isValid;
            // isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) && isValid;
        }

        return isValid;
    }

    checkFormValidation(state) {
        // console.log('StateData: ', state);        
        let formIsValid = (state.name.valid && state.email.valid && state.message.valid);
        // console.log('Form is valid: ', formIsValid);        
        return formIsValid;
    }

    inputChangedHandler(event) {


        const target = event.target;
        const value = target.value;
        const name = target.name;
        const stateData = { ...this.state };
        const inputData = { ...stateData[name] };
        inputData.value = value;
        const isValid = this.inputValidation(inputData.value, inputData.validations);
        inputData.valid = isValid;
        inputData.touched = true;
        stateData[name] = inputData;
        const formValidedResult = this.checkFormValidation(stateData);
        // console.log('Input state: ', inputData);
        // console.log('Form valid: ', formValidedResult);
        this.setState({
            [name]: inputData,
            formIsValid: formValidedResult
        });
    }

    verifyDataToSend(event) {
        event.preventDefault();
        if (this.state.formIsValid) {
            //Check if it's a valid user with recaptcha
            window.grecaptcha.ready(() => {
                try {

                    window.grecaptcha.execute(CAPTCHA_SITE_KEY, { action: 'contactpage' })
                        .then(token => {
                            this.setState({ recaptchaToken: token });
                            // console.log('My new token ==>', this.state.recaptchaToken);
                            this.submitData();
                        });

                } catch (error) {
                    // console.log('Oops! something happened: ', error);
                    this.props.formResult({ result: false });

                }
            });
        }
    }

    submitData() {
        this.setState({ sending: true });
        let data = {
            name: this.state.name.value,
            email: this.state.email.value,
            phone: this.state.phone.value,
            company: this.state.company.value,
            message: this.state.message.value,
            userToken: this.state.recaptchaToken
        }
        // console.log('Data to send:', data);

        axios(
            {
                method: 'post',
                url: 'https://spheredevs.com/api/send/email',
                responseType: 'json',
                data: data
            }
        ).then(resp => {
            console.log('Response: ', resp);
            this.props.formResult(resp.data);
            this.setState({ sending: false });
            //reset form
            if (resp.data.result) {
                this.setState({ sending: false, formIsValid: false, name: { ...this.state.name, value: '' }, email: { ...this.state.email, value: '' }, phone: { ...this.state.phone, value: '' }, company: { ...this.state.company, value: '' }, message: { ...this.state.message, value: '' } });
            }

        }).catch((error) => {
            // console.log('Oops!:', error);
            this.props.formResult({ result: false });
            this.setState({ sending: false });
        })
    }

    showErrorsOnInput(input) {
        let inputValidClass = '';
        if (!input.valid && input.touched) {
            inputValidClass = 'has-errors'
        }
        return inputValidClass;
    }

    showErrorsMessage(inputData) {
        if (!inputData.valid && inputData.touched) {
            return <span className="error" aria-live="polite">{inputData.validations.message}</span>
        }
    }

    scrollIn() {
        const animElements = anime.timeline();
        const btnRef = document.querySelector('.contact-form > .btn')
        animElements
            .add({
                targets: '.contact-form-desc',
                opacity: [0,1],
                delay: 500,
                duration: 250,
                easing: 'linear'
            })
            .add({
                targets: ['.contact-form-group', btnRef],
                opacity: [0,1],
                delay: function(el, i, l) { return i * 100; },
                easing: 'easeInSine'
            })
    }

    scrollOut() {
    }

    render() {

        return (
            <Waypoint onEnter={this.scrollIn} onLeave={this.scrollOut}>
                <section className="contact-form-container">
                    <p className="contact-form-desc">{this.props.description}</p>

                    <form className="contact-form" onSubmit={this.verifyDataToSend}>

                        <div className="contact-form-group">
                            <div className="contact-form-group-item">
                                <label>{this.props.nameLabel}</label>
                                <input className={this.showErrorsOnInput(this.state.name)} id="name-input" type="text" name="name"
                                    value={this.state.name.value}
                                    onChange={this.inputChangedHandler}
                                ></input>
                                {this.showErrorsMessage(this.state.name)}
                            </div>
                            <div className="contact-form-group-item">
                                <label>{this.props.emailLabel}</label>
                                <input className={this.showErrorsOnInput(this.state.email)} id="email-input" type="email" name="email"
                                    value={this.state.email.value}
                                    onChange={this.inputChangedHandler}
                                ></input>
                                {this.showErrorsMessage(this.state.email)}
                            </div>
                        </div>

                        <div className="contact-form-group">
                            <div className="contact-form-group-item">
                                <label>{this.props.phoneLabel}</label>
                                <input id="phone-input" type="text" name="phone"
                                    value={this.state.phone.value}
                                    onChange={this.inputChangedHandler}
                                ></input>
                            </div>
                            <div className="contact-form-group-item">
                                <label>{this.props.companyLabel}</label>
                                <input id="company-input" type="text" name="company"
                                    value={this.state.company.value}
                                    onChange={this.inputChangedHandler}
                                ></input>
                            </div>
                        </div>

                        <div className="contact-form-group">
                            <div className="contact-form-group-item">
                                <label>{this.props.messageLabel}</label>
                                <textarea className={this.showErrorsOnInput(this.state.message)} id="message-input" rows="5" cols="33" type="text" name="message"
                                    value={this.state.message.value}
                                    onChange={this.inputChangedHandler}
                                ></textarea>
                                {this.showErrorsMessage(this.state.message)}
                            </div>
                        </div>
                        <Button disabled={!this.state.formIsValid} title={(this.state.sending) ? this.props.submitingLabel : this.props.submitLabel} />

                    </form>
                    <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={CAPTCHA_SITE_KEY}
                        action='contactpage'
                        verifyCallback={this.verifyCallback}
                    />
                </section>
            </Waypoint>
        )
    }
}

export default ContactForm
