import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import Button from "../../shared/button/button";

import "./phone-section.scss"

function PhoneSection(props) {
    function scrollIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: '.phone-section',
                opacity: [0, 1],
                delay: 500,
                duration: 250,
                direction: 'reverse',
                easing: 'linear'
            })
            .add({
                targets: ['.phone-section-number', '.phone-section-actions'],
                opacity: [0, 1],
                direction: 'reverse',
                duration: 250,
                easing: 'linear'
            });
    }

    function scrollOut() {
    }
    
    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <section className="phone-section">
                <p className="phone-section-desc">{props.description}</p>
                <p className="phone-section-number">{props.phoneNumber}</p>
                <div className="phone-section-actions">
                    <Button type="A" openExternal={true} title={props.callBtnLabel} link={props.callBtnAction} />
                    <Button type="A" openExternal={true} title={props.chatBtnLabel} link={props.chatBtnAction} />
                </div>
            </section>
        </Waypoint>
    )
}

PhoneSection.propTypes = {
    description: PropTypes.string,
    phoneNumber: PropTypes.string,
    callBtnLabel: PropTypes.string,
    chatBtnLabel: PropTypes.string,
    callBtnAction: PropTypes.string,
    chatBtnAction: PropTypes.string
}

PhoneSection.defaultProps = {
    description: ``,
    phoneNumber: ``,
    callBtnLabel: ``,
    chatBtnLabel: ``,
    callBtnAction: ``,
    chatBtnAction: ``
}

export default PhoneSection

