import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import "./mini-banner.scss"

function MiniBanner(props) {

    function scrollIn() {
        anime({
            targets: '.mini-banner-desc',
            translateX: '-100%',
            direction: 'reverse',
            easing: 'linear',
            duration: 500
        });
    }

    function scrollOut() {
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <div className="mini-banner-container">
                <h1 className="mini-banner-desc">{props.description}</h1>
            </div>
        </Waypoint>
    )
}

MiniBanner.propTypes = {
    description: PropTypes.string
}

MiniBanner.defaultProps = {
    description: ``,
}

export default MiniBanner

