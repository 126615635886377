import React from 'react'
import PropTypes from 'prop-types'
import Button from "../button/button";

import "./info-modal.scss"

function InfoModal(props) {

    function closeModal(){
        props.modalState({ toClose: true });
    }
    
    return (
        <div className="info-modal-container" >
            <div className="info-modal-overlay" onClick={closeModal}></div>
            <div className="info-modal-box">

                <header className="info-modal-title-container">
                    <h4 className={ props.result ? "info-modal-title" : "info-modal-title error-message-title" }>{props.title}</h4>
                </header>
                <main className="info-modal-message-container">
                    <p className="info-modal-message">{props.message}</p>
                </main>
                <footer className="info-modal-footer">
                    <Button clickHandler={closeModal} type="B" disabled={false} title="Cerrar"/>
                </footer>

            </div>
            
        </div>
    )
}

InfoModal.propTypes = {
    modalState: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
    result: PropTypes.bool
}

InfoModal.defaultProps = {
    modalState: () => {},
    title: ``,
    message: ``,
    result: true
}

export default InfoModal

