import React, { useState } from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import MiniBAnner from "../components/shared/mini-banner/mini-banner"
import PhoneSection from "../components/contact/phone-section/phone-section"
import ContactForm from "../components/contact/contact-form/contact-form"
import SocialMediaSection from "../components/contact/social-media-section/social-media-section"

import InfoModal from "../components/shared/info-modal/info-modal"

const whatsappLinkAction = "https://api.whatsapp.com/send?phone=50683609763";
const callLinkAction = "tel:+506-8360-9763";

const ContactPage = () => {

	const [globals, setGlobals] = useState({
		modal: false,
		modalTitle: "",
		modalMessage: "",
		modalResult: true
	})

	function getContactResult(data) {
		console.log('Data from contact Form: ', data);
		if (data.result) {
			setGlobals({ modal: true, modalTitle: "Mensaje Enviado!", modalMessage: "Pronto nos contactaremos con usted.", modalResult: true });
		} else {
			setGlobals({ modal: true, modalTitle: "Oops!", modalMessage: "No se envió el mensaje. Por favor inténtalo nuevamente. O escríbenos al correo info@spheredevs.com", modalResult: false });
		}
	}

	function modalAction(modalState) {
		if (modalState.toClose) {
			setGlobals({ modal: false });
		}
	}

	function triggerFormAnimation(){
		console.log('Form scrolled!');
		
	}

	return (
		<Layout>
			<SEO title="Contacto" description="Contáctenos al +506 8360 9763 o escríbanos a info@spheredevs.com. Servicios de desarrollo web a la medida, Costa Rica." lang="es" path="contacto/" meta={[
                {
					property: `og:type`,
					content: `website`,
                },
                {
                    property: `og:image`,
                    content: 'https://cms.spheredevs.com/wp-content/uploads/2020/09/Contact-Page-Preview-Image_COMPRESED.jpg'
                },
                {
                    property: `og:locale`,
                    content: 'es_CR',
                },
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: "Contacto",
				},
				{
					name: `twitter:description`,
					content: "Contáctenos al +506 8360 9763 o escríbanos a info@spheredevs.com. Servicios de desarrollo web a la medida, Costa Rica.",
                },
                {
                    name: `twitter:creator`,
                    content: `@SphereDevs`
                },
                {
                    name: `twitter:site`,
                    content: `@SphereDevs`,
                }
            ]}/>
			<MiniBAnner description="Cuéntanos que necesitas y trabajemos juntos para crear proyectos que generen valor y logren posicionar más a su negocio." />
			<PhoneSection description="Si necesitas más información llámanos o escríbenos al WhatsApp, nos encantaría conocer más acerca de su proyecto." phoneNumber="+506 8360 9763" callBtnLabel="Llamar" chatBtnLabel="Chat" callBtnAction={callLinkAction} chatBtnAction={whatsappLinkAction} />
			<ContactForm onScroll={triggerFormAnimation} formResult={getContactResult} description="También nos puedes contactar a través de éste formulario, responderemos lo antes posible todas sus preguntas y dudas." nameLabel="Nombre"
				emailLabel="Email"
				phoneLabel="Teléfono (Opcional)"
				companyLabel="Empresa (Opcional)"
				messageLabel="Mensaje" submitLabel="Enviar" submitingLabel="Enviando..." />
			<SocialMediaSection description="Síguenos en redes sociales" facebookLinkLabel="@spheredevs" twitterLinkLabel="@SphereDevs" facebookLink="https://www.facebook.com/spheredevs" twitterLink="https://twitter.com/SphereDevs" />
			{globals.modal &&
				<InfoModal
					title={globals.modalTitle}
					message={globals.modalMessage}
					modalState={modalAction}
					result={globals.modalResult}
				/>
			}
		</Layout>
	)
}

export default ContactPage
